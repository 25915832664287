import {daxios} from '@/router'
import {formatISO, parseISO, subDays} from 'date-fns'

export default {
	namespaced: true,
	state     : () => ( {
		portfolio                 : null,
		broker_accounts           : null,
		positions                 : null,
		instrument                : null,
		instruments               : null,
		instrument_types          : null,
		instrument_positions_types: null,
		instruments_ipo           : null,
		exclusive_deals           : null,
		isLoading                 : false,
		isLoadingInstrument       : false,
		isLoadingInstruments      : false,
		currentAgreementId        : null,
		currentAgreement          : {},
		date_finish               : null,
		date_start                : null,
	} ),
	mutations: {
		SET_INSTRUMENT_POSITIONS_TYPES( state, data )
		{
			state.instrument_positions_types = data
		},
		SET_INSTRUMENTS_NULL( state )
		{
			state.instruments = null
		},
		SET_INSTRUMENT( state, data )
		{
			state.instrument = data
		},
		SET_INSTRUMENTS( state, data )
		{
			state.instruments = data
		},
		SET_INSTRUMENTS_IPO( state, data )
		{
			state.instruments_ipo = data
		},
		SET_DATES( state, {start, finish} )
		{
			state.date_start = start
			state.date_finish = finish
		},
		SET_BROKER_ACCOUNTS( state, data )
		{
			state.broker_accounts = data
		},
		SET_PORTFOLIO( state, data )
		{
			state.portfolio = data
		},
		SET_POSITIONS( state, data )
		{
			state.positions = data
		},
		SET_EXCLUSIVE_DEALS( state, data )
		{
			state.exclusive_deals = data
		},
		SET_LOADING( state, status )
		{
			state.isLoading = status
		},
		SET_LOADING_INSTRUMENT( state, status )
		{
			state.isLoadingInstrument = status
		},
		SET_LOADING_INSTRUMENTS( state, status )
		{
			state.isLoadingInstruments = status
		},
		SET_CURRENT_AGREEMENT_ID( state, id )
		{
			state.currentAgreementId = id
		},
		SET_CURRENT_AGREEMENT( state, obj )
		{
			state.currentAgreement = obj
		},
	},
	actions: {
		initializeDates( {commit, rootState} )
		{
			const today = new Date()
			const agreementDate = rootState.user.personal_data.agreement_date

			const date_start = agreementDate ? formatISO( subDays( parseISO( agreementDate ), 1 ), {representation: 'date'} ) : formatISO( subDays( today, 1 ), {representation: 'date'} )
			const date_finish = formatISO( today, {representation: 'date'} )

			commit( 'SET_DATES', {start: date_start, finish: date_finish} )
		},
		async fetchBrokerAccounts( {state, commit} )
		{
			console.log( 'fetchBrokerAccounts start' )
			commit( 'SET_LOADING', true )
			try
			{
				const response = await daxios.get( `/api/v1/client/broker_accounts?agreement_id=${state.currentAgreementId}` )
				commit( 'SET_BROKER_ACCOUNTS', response.data.data )
			}
			catch ( error )
			{
				console.error( 'Error fetching broker_accounts:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchBrokerAccounts end' )
		},
		async fetchPortfolio( {state, commit} )
		{
			console.log( 'fetchPortfolio start' )
			commit( 'SET_LOADING', true )
			try
			{
				const response = await daxios.get( `/api/v1/client/portfolio?agreement_id=${state.currentAgreementId}&date_start=${state.date_start}&date_finish=${state.date_finish}` )
				commit( 'SET_PORTFOLIO', response.data )
			}
			catch ( error )
			{
				console.error( 'Error fetching portfolio:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchPortfolio end' )
		},
		async fetchReport( {state, commit}, payload )
		{
			console.log( 'fetchReport start' )
			commit( 'SET_LOADING', true )
			try
			{
				return await daxios.get( `/api/v1/client/client-report?agreement_id=${state.currentAgreementId}&date_start=${payload.date_start}&date_finish=${payload.date_finish}` )
			}
			catch ( error )
			{
				console.error( 'Error fetching portfolio:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchReport end' )
		},
		async fetchInstruments( {state, commit}, payload = {} )
		{
			if ( !state.isLoadingInstruments )
			{
				console.log( 'fetchInstruments start' )
				commit( 'SET_LOADING', true )
				commit( 'SET_LOADING_INSTRUMENTS', true )

				// Использование URLSearchParams для добавления параметров
				let params = new URLSearchParams( {
					agreement_id: state.currentAgreementId, // всегда добавляем agreement_id
					limit       : payload.limit || 20, // добавляем limit с значением по умолчанию 20, если он не указан
				} )

				// Добавляем все остальные параметры из payload в params
				for ( let key in payload )
				{
					if ( payload.hasOwnProperty( key ) && 'limit' !== key )

						params.append( key, payload[key] )

				}

				try
				{
					const response = await daxios.get( `/api/v1/client/instruments?${params.toString()}` )
					commit( 'SET_INSTRUMENTS', response.data )
				}
				catch ( error )
				{
					console.error( 'Error fetching instruments:', error )
				}
				finally
				{
					commit( 'SET_LOADING', false )
					commit( 'SET_LOADING_INSTRUMENTS', false )
				}
				console.log( 'fetchInstruments end' )
			}
			console.log( 'fetchInstruments already fetched' )
		},
		async fetchInstrument( {state, commit}, payload = {} )
		{
			console.log( 'fetchInstrument start' )
			commit( 'SET_LOADING_INSTRUMENT', true )
			try
			{
				const response = await daxios.get( `/api/v1/client/instruments/${payload.id}?agreement_id=${state.currentAgreementId}` )
				commit( 'SET_INSTRUMENT', response.data )
			}
			catch ( error )
			{
				console.error( 'Error fetching instrument:', error )
			}
			finally
			{
				commit( 'SET_LOADING_INSTRUMENT', false )
			}
			console.log( 'fetchInstrument end' )
		},

		async fetchInstrumentPositionsTypes( {commit, state} )
		{
			console.log( 'fetchInstrumentPositionsTypes start' )
			commit( 'SET_LOADING', true )
			let url = `${import.meta.env.VITE_BACKOFFICE_URL}/api/v1/dictionary/instrument-types`
			if ( state.currentAgreementId )
				url += `?agreement_id=${state.currentAgreementId}`
			console.log( 'url:', url )

			try
			{
				const response = await daxios.get( url )
				commit( 'SET_INSTRUMENT_POSITIONS_TYPES', response.data.types )
			}
			catch ( error )
			{
				console.error( 'Error fetching instrument_positions_types:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchInstrumentPositionsTypes end' )
		},
		async fetchInstrumentsIpo( {state, commit} )
		{
			console.log( 'fetchInstrumentsIpo start' )
			commit( 'SET_LOADING', true )
			try
			{
				const response = await daxios.get( `/api/v1/client/instruments-ipo?agreement_id=${state.currentAgreementId}` )
				commit( 'SET_INSTRUMENTS_IPO', response.data )
			}
			catch ( error )
			{
				console.error( 'Error fetching instrumentsIpo:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchInstrumentsIpo end' )
		},
		async fetchExclusiveDeals( { state, commit } )
		{
			console.log( 'fetchExclusiveDeals start' )
			commit( 'SET_LOADING', true )
			try
			{
				if ( state.currentAgreementId )
				{
					const response = await daxios.get( `/api/v1/client/recommended-deals?agreement_id=${state.currentAgreementId}` )
					commit( 'SET_EXCLUSIVE_DEALS', response.data )
				}
				else
				{
					const response = await daxios.get( '/api/v1/client/recommended-deals' )
					commit( 'SET_EXCLUSIVE_DEALS', response.data )
				}
			}
			catch ( error )
			{
				console.error( 'Error fetching ExclusiveDeals:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchExclusiveDeals end' )
		},
		async fetchPositions( {state, commit} )
		{
			console.log( 'fetchPositions start' )
			commit( 'SET_LOADING', true )
			try
			{
				const response = await daxios.get( `/api/v1/client/positions?agreement_id=${state.currentAgreementId}` )
				commit( 'SET_POSITIONS', response.data )
			}
			catch ( error )
			{
				console.error( 'Error fetching positions:', error )
			}
			finally
			{
				commit( 'SET_LOADING', false )
			}
			console.log( 'fetchPositions end' )
		},
	},
}
