import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'


const router = createRouter( {
	history       : createWebHistory( import.meta.env.BASE_URL ),
	scrollBehavior: () => ( { top: 0 } ),
	routes        : [
		{
			path    : '/',
			name    : 'home',
			redirect: '/dashboard',
		},
		{
			path     : '/confirmation/nda/:step?/:hash?',
			name     : 'confirmation_nda',
			component: () => import( '@/views/ConfirmNDAWrapper.vue' ),
		},
		{
			path     : '/confirmation/broker/:step?/:hash?',
			name     : 'confirmation_broker',
			component: () => import( '@/views/ConfirmBrokerWrapper.vue' ),
		},
		{
			path     : '/client/set-password/:hash',
			component: () => import( '@/views/ClientSetPassword.vue' ),
		},
		{
			path     : '/confirmation/set-password/:hash',
			component: () => import( '@/views/ConfirmSetPassword.vue' ),
		},
		{
			path     : '/confirmation/nda/:catchAll(.*)',
			component: () => import( '@/views/ConfirmNDAWrapper.vue' ),
		},
		{
			path     : '/confirmation/:action/:data',
			component: () => import( '@/views/ConfirmUpdate.vue' ),
		},
		{
			path    : '/confirmation/:catchAll(.*)',
			redirect: to => `/confirmation/nda/${to.params.catchAll}`,
		},
		{
			path     : '/registration/login/:code_verify?',
			name     : 'login',
			component: () => import( '@/views/RegistrationWrapper.vue' ),
		},
		{
			path     : '/registration/signup',
			name     : 'signup',
			component: () => import( '@/views/RegistrationWrapper.vue' ),
		},
		{
			path     : '/registration/continue/:hash',
			name     : 'continue',
			component: () => import( '@/views/RegistrationWrapper.vue' ),
		},
		/* TO REMOVE
		{
			path     : '/404',
			name     : 'notfound',
			component: () => import( '@/views/404.vue' ),
		}, */
		{
			path     : '/:catchAll(.*)',
			component: () => import( '@/views/404.vue' ),
			// redirect: '/404', TO REMOVE
		},
		{
			path       : '/dashboard',
			name       : 'dashboard',
			component  : () => import( '@/views/DashboardView.vue' ),
			beforeEnter: ( to, from, next ) =>
			{
				if ( to.path.match( /^\/dashboard\/?$/ ) )
					next( '/dashboard/my-account' )
				else
					next()
			},
			children: [
				{
					path     : 'my-account',
					name     : 'my-account',
					component: () => import( '@/components/Dashboard/MyAccount/MyAccount.vue' ),
					children : [
						{
							path     : 'orders-transactions',
							name     : 'orders-transactions',
							component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/WrapperOrderAndTransactions.vue' ),
							children : [
								{
									path     : 'currency-exchange',
									name     : 'currency-exchange',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/CurrencyExchange.vue' ),
								},
								{
									path     : 'trade-order',
									name     : 'trade-order',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/TradeOrder.vue' ),
								},
								{
									path     : 'trade-order-ipo',
									name     : 'trade-order-ipo',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/TradeOrderIpo.vue' ),
								},
								{
									path     : 'cash-withdrawal',
									name     : 'cash-withdrawal',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/CashWithdrawal.vue' ),
								},
								{
									path     : 'transfer-between-accounts',
									name     : 'transfer-between-accounts',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/TransferBetweenAccounts.vue' ),
								},
								{
									path     : 'upload-sof',
									name     : 'upload-sof',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/UploadSoF.vue' ),
								},
								{
									path     : 'professional-investor-status',
									name     : 'professional-investor-status',
									component: () => import( '@/components/Dashboard/MyAccount/OrdersTransactions/Orders/ProfessionalInvestorStatus.vue' ),
								},
							],
						},
						{
							path     : 'frozen-funds',
							name     : 'frozen-funds',
							component: () => import( '@/components/Dashboard/MyAccount/FrozenFunds/WrapperFrozenFunds.vue' ),
						},
						{
							path     : 'my-data',
							name     : 'my-data',
							component: () => import( '@/components/Dashboard/MyAccount/MyData/MyData.vue' ),
						},
						{
							path     : 'documents',
							name     : 'documents',
							component: () => import( '@/components/Dashboard/MyAccount/Documents/DocumentList.vue' ),
						},
						{
							path     : 'reports',
							name     : 'reports',
							component: () => import( '@/components/Dashboard/MyAccount/Reports/ReportList.vue' ),
						},
						{
							path     : 'bank-details',
							name     : 'bank-details',
							component: () => import( '@/components/Dashboard/MyAccount/BankDetails/BankInfo.vue' ),
						},
					],
				},
				{
					path     : 'open-account',
					name     : 'open-account',
					component: () => import( '@/components/Dashboard/OpenAccount/AccountChoice.vue' ),
				},
				{
					path     : 'open-account/personal',
					name     : 'open-account-personal',
					component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/PersonalAccountWrapper.vue' ),
					children : [
						{
							path     : 'occupation',
							name     : 'occupation',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/Occupation.vue' ),
						},
						{
							path     : 'fatca',
							name     : 'fatca',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/Fatca.vue' ),
						},
						{
							path     : 'risk-profile',
							name     : 'risk-profile',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/RiskProfile.vue' ),
						},
						{
							path     : 'bank-details',
							name     : 'bank-details',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/BankDetails.vue' ),
						},
						{
							path     : 'proof-of-address',
							name     : 'proof-of-address',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/ProofOfAddress.vue' ),
						},
						{
							path     : 'service-plans',
							name     : 'service-plans',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/ServicePlans.vue' ),
						},
						{
							path     : 'sign',
							name     : 'sign',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/Sign.vue' ),
						},
						{
							path     : 'video-interview',
							name     : 'video-interview',
							component: () => import( '@/components/Dashboard/OpenAccount/PersonalAccount/Steps/VideoInterview.vue' ),
						},
					],
				},
				{
					path     : 'open-account/company',
					name     : 'open-account-company',
					component: () => import( '@/components/Dashboard/OpenAccount/CompanyAccount/CompanyAccountWrapper.vue' ),
				},
				{
					path     : 'my-provider',
					name     : 'my-provider',
					component: () => import( '@/components/Dashboard/MyProvider/MyProviderComponent.vue' ),
				},
				{
					path     : 'exclusive-deals',
					name     : 'exclusive-deals-page',
					component: () => import( '@/components/Dashboard/ExclusiveDeals/DealsGrid.vue' ),
				},
				{
					path     : 'exclusive-deals/:id(\\d+)',
					name     : 'single-deal',
					component: () => import( '@/components/Dashboard/ExclusiveDeals/SingleDeal.vue' ),
				},
				{
					path    : 'trading',
					redirect: '/dashboard/trading/my-portfolio',
					children: [
						{
							path     : 'my-portfolio',
							component: () => import( '../components/Dashboard/Trading/MyPortfolio.vue' ),
						},
						{
							path     : 'instrument/:id/:instrument_id?',
							component: () => import( '../components/Dashboard/Trading/InstrumentTypeComponent.vue' ),
						},
					],
				},
				{
					path     : 'support',
					name     : 'support',
					component: () => import( '@/components/Dashboard/Support/SupportForm.vue' ),
				},
				{
					path     : 'settings',
					name     : 'settings',
					component: () => import( '@/components/Dashboard/Settings/SettingsForm.vue' ),
				},
			],
		},
	],
} )

export function setupInterceptors( axiosInstance )
{
	axiosInstance.interceptors.response.use(
		response => response,
		error =>
		{
			if ( error.response && 401 === error.response.status )
				router.push( {name: 'login'} )

			return Promise.reject( error )
		},
	)
}



export const daxios = axios.create( {
	baseURL        : import.meta.env.VITE_SELF_URL,
	withCredentials: true,
} )

setupInterceptors( daxios )

export default router
