import {createStore} from 'vuex'
import user from './modules/user'
import order from './modules/order'
import nda from './modules/nda'
import trading from './modules/trading'
import account from './modules/myAccount'
import variables from './modules/globalVariables'

export default createStore( {
	modules: {
		user,
		order,
		account,
		variables,
		nda,
		trading,
	},
} )
