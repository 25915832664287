import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { daxios, setupInterceptors } from '@/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue3 } from 'bootstrap-vue-3'
import VueTheMask from 'vue-the-mask'
import { GesturePlugin } from '@vueuse/gesture'
import { Icon, addCollection } from '@iconify/vue'
import nvbIconSet from './assets/nvb.iconify.json'

import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/vue'

import './assets/css/main.css'

import store from './store'

setupInterceptors( axios )

const app = createApp( App )

Sentry.init( {
	app,
	dsn         : import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration( { router } ),
		// Sentry.replayIntegration(),
	],
	tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	// tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	// replaysSessionSampleRate: 0.1,
	// replaysOnErrorSampleRate: 1.0,
} )

addCollection( nvbIconSet )
app.component( 'NVBIcon', Icon )

app.config.globalProperties.$daxios = daxios

if ( 'TRUE' !== import.meta.env.VITE_DEBUG )
{
	const amplitudeInstance = amplitude.getInstance()
	amplitudeInstance.init( import.meta.env.VITE_AMPLITUDE_API_KEY )
	app.config.globalProperties.$amplitude = amplitudeInstance
}

app.use( store )
app.use( router )
app.use( VueAxios, axios )
app.use( BootstrapVue3 )
app.use( VueTheMask )
app.use( GesturePlugin )

app.mount( '#app' )

window.Sentry = Sentry
