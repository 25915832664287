import { daxios } from '@/router'

const state = {
	ordersData: [],
	ordersMeta: {
		current_page: 1,
		last_page   : 1,
	},
	isLoadingOrders: false,
	params         : {},
}

const mutations = {
	SET_IS_LOADING_ORDERS( state, val )
	{
		state.isLoadingOrders = val
	},
	SET_ORDERS_DATA( state, data )
	{
		state.ordersData = data
	},
	SET_ORDERS_META( state, data )
	{
		state.ordersMeta = data
	},
}
const actions = {
	async fetchOrders( { commit }, { filters, pagination } )
	{
		commit( 'SET_IS_LOADING_ORDERS', true )

		const cleanedFilters = Object.entries( filters ).reduce( ( acc, [key, value] ) =>
		{
			if ( value && 'instrument_type' !== key && 'order_status' !== key )
			{
				let val = String( value ).toLowerCase()
				if ( 'all sides' !== val )
					acc[key] = val
			}

			return acc
		}, {} )

		const cleanedPagination = Object.entries( pagination ).reduce( ( acc, [key, value] ) =>
		{
			if ( value || 0 === value )
			{ // Включаем 0, так как это валидное значение для offset
				acc[key] = value
			}
			return acc
		}, {} )

		const params = { ...cleanedFilters, ...cleanedPagination }

		// Преобразуем параметры в строку запроса
		const queryParams = new URLSearchParams( params ).toString()
		const endpoint = `/api/v1/client/trade_orders?${queryParams}`

		try
		{
			const response = await daxios.get( endpoint )
			if ( response.data.success )
			{
				// Фиксируем данные ордеров и мета-данные в состоянии
				commit( 'SET_ORDERS_DATA', response.data.data )
				commit( 'SET_ORDERS_META', response.data.meta )
				return {
					success: true,
					data   : response.data.data,
					meta   : response.data.meta,
				}
			}
			else
			{
				console.log( 'No orders data received.' )
				return { success: false, error: 'No orders data received.' }
			}
		}
		catch ( error )
		{
			console.error( 'Error fetching orders:', error )
			return { success: false, error: error.message }
		}
		finally
		{
			commit( 'SET_IS_LOADING_ORDERS', false )
		}
	},
	async fetchTrades( { commit }, { filters, pagination } )
	{
		commit( 'SET_IS_LOADING_ORDERS', true )

		const cleanedFilters = Object.entries( filters ).reduce( ( acc, [key, value] ) =>
		{
			acc[key] = String( value ).toLowerCase()
			return acc
		}, {} )

		const cleanedPagination = Object.entries( pagination ).reduce( ( acc, [key, value] ) =>
		{
			if ( value || 0 === value )
			{ // Включаем 0, так как это валидное значение для offset
				acc[key] = value
			}
			return acc
		}, {} )

		const params = { ...cleanedFilters, ...cleanedPagination }
		const queryParams = new URLSearchParams( params ).toString()
		const endpoint = `/api/v1/client/trades?${queryParams}`

		try
		{
			const response = await daxios.get( endpoint )
			if ( response.data.success )
			{
				commit( 'SET_ORDERS_DATA', response.data.data )
				commit( 'SET_ORDERS_META', response.data.meta )
			}
			else
				console.log( 'No orders data received.' )
		}
		catch ( error )
		{
			console.error( 'Error fetching orders:', error )
		}
		finally
		{
			commit( 'SET_IS_LOADING_ORDERS', false )
		}
	},
	async cancelOrder( { dispatch }, orderID )
	{
		const endpoint = `/api/v1/client/trade_orders/${orderID}/cancel`
		try
		{
			const response = await daxios.post( endpoint )
			if ( response.data.success )

				return dispatch( 'fetchOrders', {} )

			else
			{
				console.log( 'Order cancellation failed.' )
				return { success: false, error: 'Order cancellation failed.' }
			}
		}
		catch ( error )
		{
			console.error( 'Error cancelling order:', error )
			return { success: false, error: error.message }
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
