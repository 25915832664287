import { daxios } from '@/router'

const state = {
	ordersData: [],
	// ComplerdersData: [],
	ordersMeta: {
		current_page: 1,
		last_page   : 1,
	},
	transactionsData: [],
	transactionsMeta: {
		current_page: 1,
		last_page   : 1,
	},
	isLoadingOrders      : false,
	isLoadingTransactions: false,
	orderId              : null,
	orderTypeId          : null,
	statusId             : null,
	params               : {},
}

const mutations = {
	SET_IS_LOADING_ORDERS( state, val )
	{
		state.isLoadingOrders = val
	},
	SET_IS_LOADING_TRANSACTIONS( state, val )
	{
		state.isLoadingTransactions = val
	},
	SET_ORDERS_DATA( state, data )
	{
		state.ordersData = data
	},
	SET_ORDERS_META( state, data )
	{
		state.ordersMeta = data
	},
	SET_TRANSACTIONS_DATA( state, data )
	{
		state.transactionsData = data
	},
	SET_TRANSACTIONS_META( state, data )
	{
		state.transactionsMeta = data
	},
	SET_ORDER_DATA( state, data )
	{
		state.orderId = data.id
		state.orderTypeId = data.order_type.id
		state.statusId = data.status.id
		state.params = data.params
	},
}
const actions = {
	async fetchTransactions( { commit }, { filters, pagination } )
	{
		commit( 'SET_IS_LOADING_TRANSACTIONS', true )

		const cleanedFilters = Object.entries( filters ).reduce( ( acc, [key, value] ) =>
		{
			if ( value && 'order_type' !== key && 'order_status' !== key )

				acc[key] = value

			return acc
		}, {} )

		const cleanedPagination = Object.entries( pagination ).reduce( ( acc, [key, value] ) =>
		{
			if ( value || 0 === value )
			{ // Включаем 0, так как это валидное значение для offset
				acc[key] = value
			}
			return acc
		}, {} )

		const params = { ...cleanedFilters, ...cleanedPagination }

		// Преобразуем параметры в строку запроса
		const queryParams = new URLSearchParams( params ).toString()
		const endpoint = `/api/v1/client/transactions?${queryParams}`

		try
		{
			const response = await daxios.get( endpoint )
			if ( response.data.success )
			{
				// Фиксируем данные ордеров и мета-данные в состоянии
				commit( 'SET_TRANSACTIONS_DATA', response.data.data )
				commit( 'SET_TRANSACTIONS_META', response.data.meta )
				return {
					success: true,
					data   : response.data.data,
					meta   : response.data.meta,
				}
			}
			else
			{
				console.log( 'No transactions data received.' )
				return { success: false, error: 'No transactions data received.' }
			}
		}
		catch ( error )
		{
			console.error( 'Error fetching transactions:', error )
			return { success: false, error: error.message }
		}
		finally
		{
			commit( 'SET_IS_LOADING_TRANSACTIONS', false )
		}
	},
	async fetchOrders( { commit }, { filters, pagination, type = null } )
	{
		commit( 'SET_IS_LOADING_ORDERS', true )

		const cleanedFilters = Object.entries( filters ).reduce( ( acc, [key, value] ) =>
		{
			if ( value && 'order_type' !== key && 'order_status' !== key )

				acc[key] = value

			return acc
		}, {} )

		const cleanedPagination = Object.entries( pagination ).reduce( ( acc, [key, value] ) =>
		{
			if ( value || 0 === value )
			{ // Включаем 0, так как это валидное значение для offset
				acc[key] = value
			}
			return acc
		}, {} )

		const params = { ...cleanedFilters, ...cleanedPagination }

		// Преобразуем параметры в строку запроса
		const queryParams = new URLSearchParams( params ).toString()
		const endpoint = `/api/v1/client/${type ? type : 'orders_data'}?${queryParams}`

		try
		{
			const response = await daxios.get( endpoint )
			if ( response.data.success )
			{
				// Фиксируем данные ордеров и мета-данные в состоянии
				commit( 'SET_ORDERS_DATA', response.data.data )
				commit( 'SET_ORDERS_META', response.data.meta )
				return {
					success: true,
					data   : response.data.data,
					meta   : response.data.meta,
				}
			}
			else
			{
				console.log( 'No orders data received.' )
				return { success: false, error: 'No orders data received.' }
			}
		}
		catch ( error )
		{
			console.error( 'Error fetching orders:', error )
			return { success: false, error: error.message }
		}
		finally
		{
			commit( 'SET_IS_LOADING_ORDERS', false )
		}
	},

	async fetchLastOrder( { commit }, orderTypeId )
	{
		const endpoint = `/api/v1/client/last_order_data?order_type_id=${orderTypeId}`

		const response = await daxios.get( endpoint )
		const orderData = response.data.data[0]
		// console.log(response.data.data[0]);
		if ( orderData )

			commit( 'SET_ORDER_DATA', orderData )

		else

			console.log( 'No order data received.' )

	},
	async sendOrderData( { dispatch }, payload )
	{
		let methods_api = {
			post: 'create-order',
			put : 'update-order',
		}
		let url = `/api/v1/client/order/${methods_api[payload.method]}`

		try
		{
			const response = await daxios[payload.method]( url, payload.json_data )
			if ( response.data.success )
			{
				if ( !payload.ext )
					await dispatch( 'fetchLastOrder', '2' )
				return response.data
			}
			else

				return { success: false, error: 'Authorization failed' }

		}
		catch ( error )
		{
			const errorMessage = error.response && error.response.data
				? ( error.response.data.message || error.response.data )
				: error.message
			return { success: false, error: errorMessage }
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
