import {markRaw} from 'vue'

export default {
	namespaced: true,
	state     : () => ( {
		replenishModal       : false,
		thank                : false,
		showModalNonePage    : false,
		showModalGTP         : false,
		showModalSupport     : false,
		showModal            : false,
		currentModalComponent: null,
		modalProps           : {},
		modalEmits           : {},
	} ),
	mutations: {
		SET_SHOW_MODAL_SUPPORT( state, data )
		{
			state.showModalSupport = data
		},
		CLOSE_SHOW_MODAL( state )
		{
			state.showModal = false
			state.currentModalComponent = null
			state.modalProps = {}
			state.modalEmits = {}
		},
		SET_REPLENISH_MODAL( state, data )
		{
			state.replenishModal = data
		},
		SET_THANK( state, data )
		{
			state.thank = data
		},
		SET_SHOW_MODAL_NONE_PAGE( state, data )
		{
			state.showModalNonePage = data
		},
		SET_SHOW_MODAL_GTP( state, data )
		{
			state.showModalGTP = data
		},
		SET_SHOW_MODAL( state, data )
		{
			state.showModal = data
		},
		SET_CURRENT_MODAL_COMPONENT( state, component )
		{
			state.currentModalComponent = markRaw( component )
		},
		SET_MODAL_PROPS( state, props )
		{
			state.modalProps = props
		},
		SET_MODAL_EMITS( state, emits )
		{
			state.modalEmits = emits
		},
	},
	actions: {},
}
