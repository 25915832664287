import { daxios } from '@/router'
import router from '@/router'

const state = {
	personal_data  : {},
	agreements     : [],
	bank_requisites: [],
}
const mutations = {
	SET_PERSONAL_DATA( state, data )
	{
		state.personal_data = data
	},
	SET_AGREEMENTS( state, data )
	{
		state.agreements = data
	},
	SET_BANK_REQUISITES( state, data )
	{
		state.bank_requisites = data
	},
}

const actions = {
	async fetchPersonalData( { commit } )
	{
		await daxios.get( '/api/v1/client/personal-data', {withCredentials: true} )
			.then( response =>
			{
				if ( response.data.hasOwnProperty( 'data' ) && response.data.data.hasOwnProperty( 'personal_data' ) )
				{
					commit( 'SET_PERSONAL_DATA', response.data.data.personal_data )
					commit( 'SET_AGREEMENTS', response.data.data.agreements )
					commit( 'SET_BANK_REQUISITES', response.data.data.bank_requisites )
				}
				else

					console.log( 'error request debug', response.data.message )

			} )
			.catch( error =>
			{
				if ( error.response && error.response.status && 401 === error.response.status )

					router.push( {name: 'login'} )

				else

					console.log( error )

			} )
	},
	async sendEmailConfirmation( { commit }, orderId )
	{
		try
		{
			const url = `${import.meta.env.VITE_BACKOFFICE_URL}/api/v1/registration/send-email-confirmation`
			console.log( 'Sending request to:', url )

			const response = await daxios.post( url, {
				order_id: orderId,
			}, {
				withCredentials: false,
				headers        : {
					'Content-Type': 'application/json',
				},
			} )

			console.log( 'Response:', response )

			if ( response.data.success )

				return response.data

			else
			{
				console.log( 'Error:', response.data.message )
				return null
			}
		}
		catch ( error )
		{
			console.error( 'Error in sendEmailConfirmation:', error )
			return null
		}
	},
	async sendCompleteApplication( { commit }, email )
	{
		try
		{
			const url = `${import.meta.env.VITE_BACKOFFICE_URL}/api/v1/registration/send-complete-application`
			console.log( 'Sending request to:', url )

			const response = await daxios.post( url, {
				email: email,
			}, {
				withCredentials: false,
				headers        : {
					'Content-Type': 'application/json',
				},
			} )

			console.log( 'Response:', response )

			if ( response.data.success )

				return response.data

			else
			{
				console.log( 'Error:', response.data.message )
				return null
			}
		}
		catch ( error )
		{
			console.error( 'Error in sendEmailConfirmation:', error )
			return null
		}
	},
	async sendPasswordRecovery( { commit }, email )
	{
		try
		{
			const url = `${import.meta.env.VITE_BACKOFFICE_URL}/api/v1/password-recovery/send`
			console.log( 'Sending request to:', url )

			const response = await daxios.post( url, {
				email: email,
			}, {
				withCredentials: false,
				headers        : {
					'Content-Type': 'application/json',
				},
			} )

			console.log( 'Response:', response )

			if ( response.data.success )

				return response.data

			else
			{
				console.log( 'Error:', response.data.message )
				return null
			}
		}
		catch ( error )
		{
			console.error( 'Error in sendEmailConfirmation:', error )
			return null
		}
	},
	async checkClientByEmail( { commit }, email )
	{
		try
		{
			const response = await daxios.get( `${import.meta.env.VITE_BACKOFFICE_URL}/api/v1/registration/check-client?email=${email}` )

			if ( response.data.success )

				return response.data

			else
			{
				console.log( 'Error:', response.data.message )
				return null
			}
		}
		catch ( error )
		{
			console.error( 'Error in checkClientByEmail:', error )
			return null
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
